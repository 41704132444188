import { createReducer } from '@reduxjs/toolkit';

import {
	setLocalUsers,
	setLocalChanges,
	setLocalRecipes,
	setLocalVendors,
	setLocalEmployee,
	setLocalLocations,
	setLocalSpecbooks,
	setLocalContracts,
	clearLocalStorage,
	setLocalLineBuilds,
	setLocalRecipeCategories,
	setLocalLineBuildCategories,
} from '../actions/LocalStorageActions';

const initState: {
	users: UserType[];
	changes: ChangesType;
	recipes: RecipeType[];
	vendors: VendorType[];
	locations: LocationType[];
	employees: EmployeeType[];
	specbooks: SpecBookType[];
	contracts: ContractType[];
	lineBuilds: LineBuildType[];
	lastModified?: LastModifiedObjType;
	recipeCategories: RecipeCategoryType[];
	lineBuildCategories: RecipeCategoryType[];
} = {
	users: [],
	changes: {},
	recipes: [],
	vendors: [],
	locations: [],
	employees: [],
	specbooks: [],
	contracts: [],
	lineBuilds: [],
	lastModified: {},
	recipeCategories: [],
	lineBuildCategories: [],
};

const LocalStorageReducer = createReducer(initState, {
	//*-------------------Local entity reducers----------------------
	[setLocalLocations.type]: (state, { payload }) => {
		let data = payload.data as LocationType[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.locations, data, deleted, 'locationId');

		const locOrder: any = { SS: 0, AV: 1, DC: 2, CS: 3 };
		data = data.sort((a, b) => locOrder[a.shortName as any] - locOrder[b.shortName as any]);

		return {
			...state,
			locations: data,
			lastModified: {
				...state.lastModified,
				locations: new Date().toUTCString(),
			},
		};
	},
	[setLocalUsers.type]: (state, { payload }) => {
		let data = payload.data as UserType[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0) data = partialDataUpdater(state.users, data, deleted, 'userId');

		return {
			...state,
			users: data,
			lastModified: {
				...state.lastModified,
				users: new Date().toUTCString(),
			},
		};
	},
	[setLocalEmployee.type]: (state, { payload }) => {
		let data = payload.data as EmployeeType[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.employees, data, deleted, 'employeeId');

		return {
			...state,
			employees: data,
			lastModified: {
				...state.lastModified,
				employee: new Date().toUTCString(),
			},
		};
	},
	[setLocalChanges.type]: (state, { payload }) => {
		let data = payload?.data;
		const deleted = payload?.deleted;

		if ((payload?.partialUpdate && data?.logs) || deleted?.logs?.length > 0)
			data.logs = partialDataUpdater(state.changes.logs || [], data.logs, deleted.logs, 'requestId');

		if ((payload?.partialUpdate && data?.payRate) || deleted?.payRate?.length > 0) {
			data.payRate = partialDataUpdater(state.changes.payRate || [], data.payRate, deleted.payRate, 'requestId');
		}
		if ((payload?.partialUpdate && data?.rehire) || deleted?.rehire?.length > 0)
			data.rehire = partialDataUpdater(state.changes.rehire || [], data.rehire, deleted.rehire, 'requestId');

		if ((payload?.partialUpdate && data?.termination) || deleted?.termination?.length > 0)
			data.termination = partialDataUpdater(
				state.changes.termination || [],
				data.termination,
				deleted.termination,
				'requestId'
			);

		if ((payload?.partialUpdate && data?.hiring) || deleted?.hiring?.length > 0)
			data.hiring = partialDataUpdater(
				state.changes.hiring || [],
				data.hiring,
				deleted.hiring,
				'RECRUITING_EMPLOYEE_ID'
			);

		return {
			...state,
			changes: data,
			lastModified: {
				...state.lastModified,
				changes: new Date().toUTCString(),
			},
		};
	},

	[setLocalRecipes.type]: (state, { payload }) => {
		let data = payload.data as RecipeType[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.recipes, data, deleted, 'recipeId');

		return {
			...state,
			recipes: data,
			lastModified: {
				...state.lastModified,
				recipes: new Date().toUTCString(),
			},
		};
	},

	[setLocalRecipeCategories.type]: (state, { payload }) => {
		let data = payload.data as RecipeCategoryType[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.recipeCategories, data, deleted, 'categoryId');

		return {
			...state,
			recipeCategories: [
				...data.sort((a, b) => (a?.sort !== undefined && b?.sort !== undefined && a?.sort - b?.sort) || 0),
			],
			lastModified: {
				...state.lastModified,
				recipeCategories: new Date().toUTCString(),
			},
		};
	},

	[setLocalLineBuilds.type]: (state, { payload }) => {
		let data = payload.data as any[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.lineBuilds, data, deleted, 'lineBuildId');

		return {
			...state,
			lineBuilds: data,
			lastModified: {
				...state.lastModified,
				lineBuilds: new Date().toUTCString(),
			},
		};
	},

	[setLocalLineBuildCategories.type]: (state, { payload }) => {
		let data = payload.data as RecipeCategoryType[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.lineBuildCategories, data, deleted, 'categoryId');

		return {
			...state,
			lineBuildCategories: [
				...data.sort((a, b) => (a?.sort !== undefined && b?.sort !== undefined && a?.sort - b?.sort) || 0),
			],
			lastModified: {
				...state.lastModified,
				lineBuildCategories: new Date().toUTCString(),
			},
		};
	},

	[setLocalVendors.type]: (state, { payload }) => {
		let data = payload.data as any[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.vendors, data, deleted, 'vendorId');

		return {
			...state,
			vendors: data,
			lastModified: {
				...state.lastModified,
				vendors: new Date().toUTCString(),
			},
		};
	},

	[setLocalSpecbooks.type]: (state, { payload }) => {
		let data = payload.data as any[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.specbooks, data, deleted, 'specID');

		return {
			...state,
			specbooks: data,
			lastModified: {
				...state.lastModified,
				specbooks: new Date().toUTCString(),
			},
		};
	},

	[setLocalContracts.type]: (state, { payload }) => {
		let data = payload.data as any[];
		const deleted = payload?.deleted;

		if (payload?.partialUpdate || deleted?.length > 0)
			data = partialDataUpdater(state.contracts, data, deleted, 'contractID');

		return {
			...state,
			contracts: data,
			lastModified: {
				...state.lastModified,
				contracts: new Date().toUTCString(),
			},
		};
	},

	//*----------------------------------------------------------------

	[clearLocalStorage.type]: (state, _) => {
		return initState;
	},
});

const partialDataUpdater = (data: any[], partialData: any[], deletedIds: string[], identityKey: string) => {
	let currentData = [...data];

	partialData?.forEach(pd => {
		const targetIndex = currentData.findIndex(cd => cd?.[identityKey] === pd?.[identityKey]);
		if (targetIndex >= 0) currentData[targetIndex] = pd;
		else currentData = [...currentData, pd];
	});

	if (deletedIds?.length > 0) {
		deletedIds.forEach(di => {
			const targetIndex = currentData.findIndex(cd => cd?.[identityKey] === di);
			if (targetIndex >= 0) currentData.splice(targetIndex, 1);
		});
	}

	return currentData;
};

export type LocationType = {
	slug?: string;
	code?: string;
	name?: string;
	color?: string;
	photo?: string;
	minWage?: number;
	zipCode?: string;
	shortName?: string;
	locationId?: string;
	overTimeCoef?: number;
	default_rate?: { [jobCode: string]: number };
};

export type EmployeeType = {
	status?: string;
	lastName?: string;
	oracleID?: string;
	payrollID?: string;
	firstName?: string;
	employeeId?: string;
	middleName?: string;
	locationId?: string;
	orientationDate?: string;
	payrollInfo?: { [key: string]: number };
	payrollJobsInfo?: { [key: string]: string[] };
	payrollJobsInfoRates?: { [key: string]: any };
};

export type UserType = {
	name: string;
	email: string;
	userId?: string;
	locationId?: string;
	access?: {
		isSuper?: boolean;
		locations?: string[];
		app_modules?: string[];
		web_modules?: string[];
	};

	sessions?: UserSessionType[];
};

export type UserSessionType = {
	service: string;
	exp_date: string;
	interface: string;
	createdAt: string;
	app_version: string;
	ACTIVE_SESSION_ID: string;
};

export type RecipeType = {
	name: string;
	photo?: string;
	userId?: string;
	recipeId: string;
	galleryId?: string;
	thumbnail?: string;
	locationId?: string;
	categoriesId: string[];
	last_update_date?: string;
	status?: string | 'ENABLE' | 'DISABLE';
};

export type RecipeCategoryType = {
	name: string;
	sort?: number;
	photo?: string;
	categoryId: string;
	thumbnail?: string;
	galleryId?: string;
	locationId?: string;
};

export type ChangesType = {
	logs?: any[];
	hiring?: any[];
	payRate?: any[];
	rehire?: any[];
	termination?: any[];
};

export type LineBuildType = {
	name?: string;
	photo?: string;
	status?: string;
	userId?: string;
	thumbnail?: string;
	galleryId?: string;
	categoriesId: any[];
	locationId?: string;
	lineBuildId?: string;
	last_update_date?: string;
};

export type ContactType = {
	type?: string;
	name?: string;
	notes?: string;
	phone?: string;
	email?: string;
	vendor?: string;
	signer?: string;
	contactID?: string;
};

export type VendorType = {
	name?: string;
	notes?: string;
	email?: string;
	tags?: string[];
	address?: string;
	website?: string;
	vendorId?: string;
	isActive?: string;
	createdAt?: string;
	isDeleted?: string;
	locationId?: string;
	phone_number?: string;
	locationIDs?: string[];
	alternate?: ContactType;
	preferred?: ContactType;
	default_contact?: string;
};

export type SpecBookType = {
	title?: string;
	photo?: string;
	vendor?: string;
	specID?: string;
	tags?: string[];
	updatedAt?: Date;
	gallery?: string;
	category?: string;
	thumbnail?: string;
	galleryId?: string;
	Updated_By?: string;
	isDeleted?: boolean;
	Last_Update?: string;
	description?: string;
	locationIDs?: string[];
	related_specs?: string[];
};

export type ContractType = {
	title?: string;
	vendor?: string;
	signer?: string;
	exp_date?: string;
	updatedAt?: string;
	sign_date?: string;
	attachment: string;
	contractID?: string;
	isDeleted?: boolean;
	locationId?: string;
	auto_renew?: boolean;
	last_changed?: string;
};

export type LastModifiedEntities =
	| 'users'
	| 'changes'
	| 'recipes'
	| 'vendors'
	| 'employee'
	| 'locations'
	| 'specbooks'
	| 'contracts'
	| 'lineBuilds'
	| 'recipeCategories'
	| 'lineBuildCategories';

export type LastModifiedObjType = {
	users?: string;
	changes?: string;
	recipes?: string;
	vendors?: string;
	employee?: string;
	locations?: string;
	specbooks?: string;
	contracts?: string;
	lineBuilds?: string;
	recipeCategories?: string;
	lineBuildCategories?: string;
};

export default LocalStorageReducer;
